import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
// import root from '@/minxin/root'
export default {
  name: 'inventoryCorrection',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      isShow: false,
      formSearch: {
        KeyWords: '',
        PageIndex: 1,
        PageSize: 10,
        StockCorrectNo: '',
        StockCorrectName: '',
        BeginDate: '',
        EndDate: '',
        time: []
      },
      adSearchForm: [
        { name: '盘点单号', value: 'StockCorrectNo' },
        // { name: '盘点仓库', value: 'ApprovalDate' },
        { name: '盘点名称', value: 'StockCorrectName' },
        { name: '筛选时间', date: true, value: 'time' }
      ],
      selects: []
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    // 发起盘点
    initiateInventory() {
      this.$router.push({
        name: 'initiateInventory',
        params: { idx: 0 }
      })
    },
    selectAll(e) {
      console.log(e)
      this.selects = e
    },
    // 导出
    export_() {
      const obj = {
        KeyWords: '',
        PageIndex: 1,
        PageSize: -1,
        StockCorrectNo: '',
        StockCorrectName: '',
        BeginDate: '',
        EndDate: '',
        CheckedIdxs: ''
      }
      for (const key in this.formSearch) {
        obj[key] = this.formSearch[key]
      }
      obj.CheckedIdxs = ''
      if (this.selects.length > 0) {
        this.selects.map((item, index) => {
          if (index !== this.selects.length - 1) {
            return obj.CheckedIdxs += item.IDX + ','
          }
          return obj.CheckedIdxs += item.IDX
        })
      }
      this.$refs.ExportLoading.open()
      this.$api.StockCorrect_Export(obj).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('盘点修正')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
      })
    },
    // 发起高级搜索
    searchAd() {
      if (this.formSearch.time.length > 0) {
        this.formSearch.BeginDate = this.formSearch.time[0]
        this.formSearch.EndDate = this.formSearch.time[1]
      }
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.isShow = false
    },
    // 查看详情
    viewDetail(idx, status) {
      if (status === 1) {
        this.$router.push({
          name: 'initiateInventory',
          params: { status, idx }
        })
      }
      if (status === 2 || status === 3) {
        this.$router.push({
          name: 'inventoryDetails',
          params: { idx, status }
        })
      }
    },
    // 打开高级搜索框
    search() {
      this.isShow = true
    },
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetStockCorrectInfoList(this.formSearch)
      console.log('response', response)
      if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    }
  }
}
