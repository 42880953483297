<template>
  <div class="inventoryCorrection">
    <PageHeader title="盘点修正" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20 ">
      <div
        class="ModelBttton-white-30 m-right-10"
        @click="export_"
      >
        <i class="el-icon-upload2 f15 p-right-10" /> 导出
      </div>
      <div class="flex m-lr-10 SearchBox_30 " style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过盘点编号、盘点名称搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="search">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            class="cursor"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <el-button type="primary" class="margin-left-20" size="mini" @click="initiateInventory">新增盘点</el-button>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'time','BeginDate','EndDate')" />
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        tooltip-effect="dark"
        height="calc(100vh - 330px)"
        show-header
        row-key="IDX"
        border
        :fit="true"
        @selection-change="selectAll"
      >
        <!--    @select-all="selectAll"
        @select="select" -->
        <el-table-column type="selection" width="40" />
        <el-table-column label="盘点单号" prop="StockCorrectNo" show-overflow-tooltip />
        <el-table-column label="盘点名称" prop="StockCorrectName" show-overflow-tooltip />
        <el-table-column label="库存冻结时间" prop="LunchTime" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.LunchTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="盘点时间" prop="WriteTime" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="发起人" prop="LunchUserName" show-overflow-tooltip />
        <el-table-column label="盘点人" prop="CreateUserName" show-overflow-tooltip />
        <el-table-column label="备注" prop="Remark" show-overflow-tooltip />
        <el-table-column label="盘点状态" prop="strStatus" show-overflow-tooltip />
        <el-table-column label="操作" prop="" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary" @click="viewDetail(scope.row.IDX,scope.row.Status)">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/management/inventoryCorrection'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
